<template>
    <div v-if="!isSearchEngine" class="headerBlock flex flex-col text-base-content w-auto px-3" :title="title.label"  
        :class="(title.label === 'Query Name' || title.label === 'Query') ? 'col-span-2' : 'col-span-1'">
        <div class="headerBlock__title font-semibold text-base-content py-2 px-4">
            {{ title.label }} 
        </div>
    </div>
    <div v-else class="headerBlock flex flex-col text-base-content w-auto px-3 col-span-1" :title="title.label">
        <div class="headerBlock__title font-semibold text-base-content py-2 px-4">
            {{ title.label }} 
        </div>
    </div>

</template>
  
<script>
  
  export default {
    props: {
        title: {
            type: Object,
            required: true,
        },
        currentTab: {
            type: String,
            default: ()=> ''
        },
        isSearchEngine:{
            type:Boolean
        }
    },
    components: {

    },
  }
  </script>
  
  <style lang="scss" scoped>

  </style>