<template>
    <div v-if="data" class="text-gray-800 w-full bg-white rounded-md">
        <!-- Table View Container with Merged Borders -->
        <div :class="['grid', isSearchEngine ? 'grid-cols-8' : 'grid-cols-6', 'gap-0 w-full rounded-md border-t border-gray-300']">
            <!-- Name Field -->
            <div :class="[ isSearchEngine ? 'col-span-1 md:col-span-1' : 'col-span-1 md:col-span-2', 'px-2 border-t border-r border-gray-300']" v-tippy :content="fetchLabel(data.name)">
                <input 
                    v-if="edited"
                    v-model="data.name"
                    @click.stop="$emit('fieldNameClick', data)"
                    :class="!data.name || !isValidName(data.name) || edited ? 'border border-line border-gray-200 p-1' : ''"
                    type="text" 
                    class="section_name w-full md:w-42 bg-transparent border-0 outline-none p-1 text-md text-base-content rounded focus:border-none border-b border-solid border-gray-300 truncate" 
                />
                <span v-else 
                    class="section_name bg-transparent truncate w-full md:w-42 border-0 outline-none p-1 text-sm text-base-content font-normal truncate">
                    {{ fieldName }}
                </span>
            </div>

            <div v-if="isSearchEngine" class="col-span-1 px-2 border-t border-r border-gray-300" v-tippy :content="fetchLabel(data.name)">
                <input 
                    v-if="edited"
                    v-model="data.meta.category"
                    @click.stop="$emit('fieldNameClick', data)"
                    :class="edited ? 'border border-line border-gray-200 p-1 bg-gray-200' : ''"
                    type="text" 
                    :disabled="edited"
                    class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 rounded-md overflow-ellipsis"  
                />
                <span v-else 
                    class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 rounded-md overflow-ellipsis"> 
                    {{ fieldCategory }}
                </span>
            </div>

            <div v-if="isSearchEngine" class="col-span-1 px-2 border-t border-r border-gray-300" v-tippy :content="fetchLabel(data.name)">
                <input 
                    v-if="edited"
                    v-model="data.meta.sub_category"
                    @click.stop="$emit('fieldNameClick', data)"
                    :class="edited ? 'border border-line border-gray-200 p-1 bg-gray-200' : ''"
                    :disabled="edited"
                    type="text" 
                    class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 rounded-md overflow-ellipsis" 
                />
                <span v-else 
                    class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 rounded-md overflow-ellipsis"  >
                    {{ fieldSubCategory }}
                </span>
            </div>
            <!-- Query Field -->
            <div :class="[ isSearchEngine ? 'col-span-1 md:col-span-1' : 'col-span-1 md:col-span-2', 'px-2 border-t border-r border-gray-300']">
                <input 
                    v-model="stringifiedQuery" 
                    :disabled="!edited" 
                    :class="!data.query || edited ? 'border border-line border-gray-200 p-1' : ''"
                    type="text" 
                    class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 rounded-md overflow-ellipsis" 
                />
            </div>

            <!-- Default Checkbox -->
            <div class="flex justify-center col-span-1 items-center px-2 border-t border-r border-gray-300">
                <input 
                    :value="data.default" 
                    v-model="data.default" 
                    type="checkbox" 
                    class="checkbox w-4 h-4 border-gray-500 outline-none"
                    :class="!edited ? 'pointer-events-none opacity-50' : 'pointer'" 
                />
            </div>

            <!-- Edit/Save/Delete Buttons (when in editing mode) -->
            <div v-if="edited" class="flex justify-evenly col-span-1 items-center px-2 border-t border-r border-gray-300">
                <div v-if="this.$route.params.action !== 'view'" @click.stop="onSave(data)"
                    class="h-8 w-8 flex items-center justify-center rounded-full hover:text-white-text transition-all duration-150">
                    <font-awesome-icon 
                        icon="save" 
                        class="cursor-pointer text-sm text-green-500"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" 
                    />
                </div>
                <div class="py-1">
                    <font-awesome-icon 
                        v-if="this.$route.params.action !== 'view'" 
                        v-allow="'block.delete'"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                        icon="times" 
                        @click.stop="close" 
                        class="cursor-pointer text-red-500 w-5 h-5 hover:text-red-focus text-md" 
                        v-tippy content="Delete Block" 
                    />
                </div>
            </div>

            <!-- Edit/Delete Buttons (when not in editing mode) -->
            <div v-else class="flex justify-evenly col-span-1 items-center px-2 border-t border-r border-gray-300">
                <div v-if="this.$route.params.action !== 'view'" class="h-8 w-8 flex items-center justify-center rounded-full hover:text-white-text transition-all duration-150" @click.stop="onEdit()">
                    <font-awesome-icon 
                        icon="edit" 
                        class="cursor-pointer text-sm text-primary"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                        :class="edited ? 'pointer-events-none opacity-50' : 'pointer'" 
                    />
                </div>
                <div class="py-1">
                    <font-awesome-icon 
                        v-if="this.$route.params.action !== 'view'" 
                        v-allow="'block.delete'"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                        icon="trash" 
                        @click.stop="onDelete(data)" 
                        class="cursor-pointer text-red-500 w-4 h-4 hover:text-red-focus text-sm"
                        v-tippy content="Delete Block" 
                        :class="edited ? 'pointer-events-none opacity-50' : 'pointer'" 
                    />
                </div>
            </div>

            <!-- Delete Field Modal -->
            <deleteFieldModal
                :open="deleteFieldModalOpen"
                @close="closeDeleteFieldModal"
                v-if="workflowData"
                :workflowData="workflowData"
            />
        </div>
    </div>
</template>

<script>

import { fetchLabel} from "@shared/utils/functions"
import axios from "@/axios";
import deleteFieldModal from "./delete-pre-build-query.vue";


export default {
    name: "add-block",
    
    data() {
        return{
            stringifiedQuery: JSON.stringify(this.data.query),
            edited: false,
            deleteFieldModalOpen: false,
            workflowData:{},
            isNameValid: true,
            previousData: '',
            previousQuery: '',
      }
    },
    components: {
        deleteFieldModal
    },
    async mounted() {
        console.log("this.data ", this.data)
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        isSearchEngine:{
            type:Boolean
        }
    },
    methods:{
        fetchLabel,
        onEdit() {
            if (this.$route.params.action == 'view') {
                return
            }
            if (!this.data.name) {
                this.$toast.error("Query name cannot be empty");
                return;
            }
            this.previousData = this.data.name;
            this.previousQuery = this.stringifiedQuery;
            this.edited = !this.edited;

        },
        async onSave(value)
        {
            this.edited = false;
            if(this.isValidJson)
                if (!this.data.name) {
                    this.$toast.error("Query name cannot be empty");
                    return;
                }
                else{
                const parsed = JSON.parse(this.stringifiedQuery); // Parse to remove backslashes
                    let payload;
                    payload= {
                        name: value.name,
                        query: parsed,
                    }
                    if (value.default) {
                        payload.default = value.default;
                    }           
                    try {
                        let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}`;
                        let { data } = await axios.put(url, payload);
                        this.$toast.success(data?.detail);
                        
                    } catch (error) {
                        console.log(error)
                        this.$toast.error(error.response.data.detail);
                    }
                }
            else{
                this.$toast.error("Not a valid Json")
                this.stringifiedQuery = this.previousQuery;

            }
        },
        getFieldName(field) {
            return field.name;
        },
        getFieldQuery(field) {
            return field.query;
        },
        getFieldCategory(field){
            return field.meta.category
        },
        getFieldSubCategory(field){
            return field.meta.sub_category
        },
        close(){
            this.edited = false;
            this.data.name =  this.previousData;
            this.stringifiedQuery = this.previousQuery;

        },
        async onDelete(value)
        {
            try {
                let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}/workflow`;
                let { data } = await axios.get(url);
                const result = this.checkAllArraysEmpty(data);
                if(!result){
                    this.deleteFieldModalOpen = true
                    this.workflowData = data
                }
                else{
                    this.deleteQuery(value)
                }
                
            } catch (error) {
                console.log(error)
                this.$toast.error(error.response.data.detail);
            }
        },
        closeDeleteFieldModal() {
            this.deleteFieldModalOpen = false;
        },
        checkAllArraysEmpty(data) {
            for (const key in data) {
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    return false; // If any array is not empty, return false
                }
            }
            return true; // If all arrays are empty, return true
        },
        async deleteQuery(value){
            try {
                let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}`;
                let { data } = await axios.delete(url);
                this.$toast.success(data.detail);
                this.$emit("refresh");
                
            } catch (error) {
                console.log(error)
                this.$toast.error(error.response.data.detail);
            }
        },
        isValidName(name) {
            // Regular expression to check if the name is valid
            const regex = /^(?!\s)(?!.*\s$)[A-Za-z0-9 ]+$/;
            return regex.test(name);  // Returns true if valid, false otherwise
        }
   },
   computed:{
        fieldName() {
            return this.getFieldName(this.data);
        },
        fieldQuery(){
            return this.getFieldQuery(this.data);
        },
        fieldCategory(){
            return this.getFieldCategory(this.data);
        },
        fieldSubCategory(){
            return this.getFieldSubCategory(this.data);
        },
        isValidJson() {
            try {
                JSON.parse(this.stringifiedQuery);
                return true; // Valid JSON
            } catch (e) {
                return false; // Invalid JSON
            }
        },
   }
};
</script>

<style scoped>
    /* Optional styling for the grid structure */
    .grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 0; /* Remove gap between the grid items to make the borders look merged */
    }
    .section_name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .checkbox {
        accent-color: #4CAF50;
    }
    .hover\:text-white-text:hover {
        color: white;
    }
</style>
