<template>
    <div class="w-full">
        <div class="w-full border rounded-md bg-gray-100">
            <div class="grid grid-cols-6 divide-x-2">
                <queryHeader
                    v-for="(role, id) in columnList"
                    :key="id"
                    class="roleHeader__item"
                    :title="role"
                    :isSearchEngine="isSearchEngine"
                />
            </div>
            <span v-if="isLoading" class="flex h-44 min-w-full bg-card-bg items-center flex-col justify-center rounded-md">
                <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                <span> Loading... </span>
            </span>
            <div v-else class="">
                <queryData
                    v-for="(role, id) in rowData"
                    :key="id"
                    class="roleHeader__item"
                    :data="role"
                    @refresh="refresh"
                    :isSearchEngine="isSearchEngine"
                />
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
  import queryHeader from "./components/prebuild-queries-header.vue";
  import queryData from "./components/prebuild-queries-data.vue";
  import axios from "@/axios";
  import loader from "@shared/loader";
  
  export default {
    name: 'field-roles-header',
    components: {
      queryHeader,
      queryData,
      loader,
    },
    data() {
      return { 
        columnList:[
            {
                label: "Query Name",
                field: "query_name",
            },
            {
                label: "Query",
                field: "query",
            },
            {
                label: "Default",
                field: "default",
            },
            {
                label: "Actions",
                field: "actions",
            }
        ],
        rowData:[],
        inCheckRole:null,
        isLoading: false,
        isSearchEngine: false,
      }
    },
    props:{

    },
    mounted(){
        this.getPrebuildquerydata(this.$route.params.id)
    },
    methods: {
        async getPrebuildquerydata(id){
            this.isLoading = true;
            try {
                    let url = `/tenant-check/${id}/pre-build-query`;
                    let { data } = await axios.get(url);
                    this.rowData = data; 

                    if (this.rowData && this.rowData.length > 0) {
                        let metaValue = this.rowData.some(item => item.meta && ('category' in item.meta || 'sub_category' in item.meta)) 
                        if (metaValue) {
                            this.isSearchEngine = true
                            // Add "Category" and "Sub Category" at the 2nd and 3rd positions of the columnList
                            this.columnList.splice(1, 0, {
                                label: "Category",
                                field: "category",
                            });

                            this.columnList.splice(2, 0, {
                                label: "Sub Category",
                                field: "subcategory",
                            });
                        }
                    }               
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isLoading = false;
                }
        },
        refresh(){
            this.getPrebuildquerydata(this.$route.params.id)
        }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .item-2 {
    background-color: gray
}
  
 
  </style>
  