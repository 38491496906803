<template>
    <div  v-if="checkData" class="flex gap-6 flex-col">

        <button v-allow="'check.delete'" v-if="$route.params.action !== 'view'" @click="deleteCheck(checkData)" :disabled="$route.params.action == 'view'" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm check-delete-btn">
            <font-awesome-icon icon="trash" />
        </button>
        <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
        <div class="flex flex-col gap-3 p-3">
            <div class="flex w-full gap-6 items-stretch">
                <div class="flex flex-col w-2/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <FormulateInput 
                            class="w-full text-base-content" 
                            :label="$t('pages.email_admin.check_name')" 
                            :placeholder="$t('pages.email_admin.check_name')" 
                            type="text"
                            validation="required" 
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                            :disabled="$route.params.action == 'view'" 
                            @input="handleInput($event, 'name')"
                            v-model="checkData.name" 
                        />
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <FormulateInput 
                            class="w-full text-base-content" 
                            :label="$t('pages.check_admin.check_label')" 
                            :placeholder="$t('pages.check_admin.check_label')" 
                            type="text"
                            validation="required" 
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                            :disabled="$route.params.action == 'view'" 
                            @input="handleInput($event, 'label')"
                            v-model="checkData.label"
                        />
                    </div>
                    <!-- <div class="flex flex-col gap-3">
                        <div class="flex items-center gap-5">
                            <FormulateInput 
                                class="w-full text-base-content" 
                                label="SLA Period" 
                                placeholder="SLA Period (days)" 
                                type="text" 
                                min="1" 
                                validation="required|number|min:1,number" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :disabled="$route.params.action == 'view'" 
                                @input="handleInput($event, 'sla')"
                                v-model="checkData.sla" 
                            />
                            <span class="text-base-content text-md font-semibold w-0.5/4">Days</span>
                        </div>
                    </div> -->
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> {{ $t('pages.check_admin.check_category') }} </span>
                        <span class="w-full text-base-content">{{ checkData.category }}</span>
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> {{ $t('pages.check_admin.check_type') }} </span>
                        <span class="w-full text-base-content">{{ checkData.subtype_name }}</span>
                    </div>
                </div>
                <div class="flex flex-col w-3/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <FormulateInput
                            v-model="checkData.entity_types"
                            @input="handleInput($event, 'entity_types')"
                            validation="required"
                            class="w-full"
                            :label="$t('pages.field_table.entity_type')"
                            :placeholder="$t('pages.field_table.select_entity_type')"
                            type="multi-select"
                            variant="alt"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            :options="entityTypeOptions"
                            :config="{
                                ...ENTITY_TYPES_DROPDOWN_CONFIG,
                                limit: 4,
                            }"
                            :disabled="$route.params.action == 'view'"
                        />
                    </div>
                    <div class="flex flex-col gap-3 w-full h-full">
                        <FormulateInput 
                            class="w-full" 
                            :rows="10" 
                            :label="$t('pages.check_admin.check_description')" 
                            :placeholder="$t('pages.check_admin.check_description')" 
                            type="textarea" validation="required" 
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                            :disabled="$route.params.action == 'view'"
                            @input="handleInput($event, 'description')"
                            v-model="checkData.description" 
                        />
                    </div>
                </div>
            </div>
            <div v-if="checkData && checkData.parameters && checkData.parameters.parameters">
                <span class="text-base-content text-md font-bold"> {{ $t('pages.check_admin.check_parameters') }} </span>
                <div class="mt-2">
                    <div class="rounded flex gap-2 items-center" v-for="params in checkData.parameters.parameters" :key="params.name">
                        <div class="bg-indigo-200 py-1 px-2 text-base-content rounded">
                            {{ params.name }}
                        </div>
                        <input 
                            v-model="params.value"
                            @input="handleInput($event, 'parameters')" 
                            :type="params.value_type" 
                            min="0" 
                            @keypress="isNumber" 
                            class="text-center border border-b border-gray-200 h-8 w-12 rounded flex items-center justify-center"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-2 flex justify-end max-w-full gap-4" v-allow="'check.edit'" v-if="$route.params.action !== 'view'">
                <Button 
                    @click="saveCheck()" 
                    class="btn-primary float-right mt-4" 
                    :text="$t('pages.field_table.save')" 
                    :disabled="hasErrors || !isDirty || $route.params.action == 'view'"
                />
            </div>
        </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@/components/button";
import { isNumber } from "@/plugins/functions";
import { mapActions, mapState } from 'vuex';
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";

export default {
    name: "check-details",
    components: {
        Button,
    },
   props: {
        checkData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
        }
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.checksAdmin.isDirty
        }),
    },
    methods: {
        ...mapActions([
            'handleCheckDataChange'
        ]),
        isNumber,
        handleInput(value, key) {
            this.handleCheckDataChange({ key, value });
        }, 
        saveCheck() {
            this.$emit("handleSaveCheck");
        },
        deleteCheck(checkDetails) {
            this.$emit("handleDeleteCheck", checkDetails);
        },
    },

    // async mounted() {
    //     await this.fetchEntityTypeOptions();
    // },
};
</script>

<style>
.check-delete-btn {
    position: absolute;
    top: 12px;
    right: 30px;
}
</style>
