<!-- Localized -->
<template>
    <div v-if="open" class="addblocktosection flex-col h-screen w-screen flex items-center justify-center fixed inset-0" @click.stop="closeModal()">
        <div class="h-auto flex-col px-6 py-3 space-y-4 flex items-center justify-center rounded-md" @click.stop>
            <div class="flex space-x-6" style=" height: auto">
                        <!-- {{ listType.listModalTitle || "" }} -->
                <div class="max-h-full w-full  max-w-md  overflow-y-auto flex flex-col">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">
                        {{ $t('pages.check_admin.delete_query') }}
                        <button class="bg-white cursor-pointer rounded-full h-6 w-6 justify-center items-center flex" @click="closeModal()">
                            <font-awesome-icon icon="times" class="text-gray-800" />
                        </button>
                    </div>
                    <div class="bg-card-bg rounded-b-lg flex-1 h-full p-5 flex flex-col overflow-y-auto max-w-full">
                        
                        <div class="text-gray-700 mb-3"> {{ $t('pages.check_admin.delete_query_message') }}  </div>
                        <template>
                            <div v-for="(value, key) in workflowData" :key="key">
                                <label v-if="value.length > 0" for="#" class="capitalize text-primary cursor-pointer hover:text-primary-focus" @click="showDetails(value)">
                                    {{ $t('pages.check_admin.workflow_engines', { value: value.length, name: formatLabel(key) }) }}
                                </label>
                            </div>
                            <div class="flex space-y-3 flex-col mb-4" >
                                <p class="text-primary cursor-pointer hover:text-primary-focus"
                                v-for="used in usedInDetailsFiltered" :key="used.label+used.count" @click="showDetails(used)">
                                    <span class="mr-2 font-medium" v-text="used.count" />
                                    <span v-text="used.label" />
                                </p>
                            </div>
                        </template>
                        <div class="grid grid-cols-4 gap-x-6 ">
                            <button class="bg-primary text-white hover:bg-gray-300 transition-colors  hover:text-white-700 text-white-600 px-3 font-small py-2 rounded-xl " @click="closeModal">{{ $t('actions.OK') }}</button>

                        </div>
                    </div>
                </div>  
                <div class="max-h-full w-full max-w-md   overflow-y-auto flex flex-col" v-if="showDetailsPanel">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">
                        <div class="capitalize">{{ $t('pages.check_admin.detail_workflow_engines', { value: activeDetail[0].type }) }}</div>
                        <button class="bg-white cursor-pointer rounded-full h-6 w-6 justify-center items-center flex" @click="closeDetailsPanel()">
                            <font-awesome-icon icon="times" class="text-gray-800" />
                        </button>
                    </div>
                    <div class="bg-card-bg rounded-b-lg flex-1 h-full p-5 flex flex-col overflow-y-auto max-w-full">
                        <div v-if="loadingLists" class="flex justify-center items-center flex-1">
                            <Loader />
                        </div>

                        <!-- <ListView v-else :showSearch="false" :listItems="filteredDetails" class="max-w-full min-h-16"   :showBorder="false" @handleFilterResult="handleFilterResult">
                            <template #item="{ item }"> -->
                            <div v-else class="flex flex-col">

                                <div class="search_container mb-4">
                                    <Input v-model="searchKeyword"  :placeholder="$t('pages.field_table.search')" icon="search" />
                                </div>
                                <div class="bg-gray-100 mb-2  max-w-full py-2 px-4 w-full truncate rounded-lg" v-for="item in activeDetail" :key="item.name" >
                                    {{item.name}}
                                </div>
                            </div>
                            <!-- </template>
                        </ListView> -->
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
// import ListView from "@shared/components/list-view";
import Loader from "@shared/components/loader"
import Input from "@shared/input"
export default {
    data() {
        return {
            confirmDelete: 'no',
            confirmInput: '',
            activeDetail: null,
            showDetailsPanel: false,
            searchKeyword: ""
            
        }
    },
    props: {
        open: {
            default: false,
            type: Boolean,
        },
        loadingLists: {
            type: Boolean,
            default: false,
        },
        isDeleteLoading: {
            type: Boolean,
            default: false,
        },
        workflowData: {
            type: Object,
            default: () => {},
            required: true
        },
        usedInDetails: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        usedInDetailsFiltered() {
            return this.usedInDetails.filter(ele => ele.count > 0)
        },
        isBtnDisabled() {
            return this.confirmInput.toLowerCase() === 'yes'
        },
        filteredDetails() {
            if (this.activeDetail && this.activeDetail.details) {
                return this.activeDetail.details.filter(ele => ele.name.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            } else return []
        }
    },
    components: {
        // ListView,
        Loader,Input
    },
    methods: {
        handleFilterResult(str) {
            this.searchKeyword = str || ""
        },
        closeModal() {
            // this.handleSelect(this.activeElement);
            this.confirmInput  = ''
            this.confirmDelete = 'no'
            this.closeDetailsPanel()
            this.$emit("close");
        },
        closeDetailsPanel() {
            this.searchKeyword = ''
            this.activeDetail = null 
            this.showDetailsPanel = false 
        },
        showDetails(value) {
            this.searchKeyword = ''
            this.activeDetail = value  
            this.showDetailsPanel = true 
        },
        formatLabel(key) {
            // Capitalize and format the key for human-friendly display
            const formatted = key.charAt(0).toUpperCase() + key.slice(1);
            return formatted.charAt(0).toUpperCase() + formatted.slice(1);
        },
        async detailClicked(item){
            const link = await this.$router.resolve({name: item.route, params:{ [item.routeParams || 'id']: item.id}})
            window.open(link.href, '_blank')
        }
        
    }

}
</script>

<style lang="scss" scoped>
.addblocktosection {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
.btn-red{
    background: #ff7777;
    color: #fff;
    &:hover {
        background: #f17474;

    }
}
</style>